<template>
  <div>
    <ribbon class="has-background-white">
      <div class="column is-12 has-text-centered">
        <img
          src="/assets/images/interface/icons/001-dashboard.svg"
          width="64"
        />
        <h2 class="is-size-3">Reseller controls</h2>
      </div>
    </ribbon>

    <ribbon class="has-background-white has-border-top" padding="none">
      <div class="has-padding-left-100 has-padding-right-100">
        <horizontal-nav :routes="routes" :is-borderless="true" />
      </div>
    </ribbon>

    <ribbon class="has-inset-shadow-top">
      <router-view />
    </ribbon>
  </div>
</template>

<script>
export default {
  name: "Reseller",
  data() {
    return {
      routes: [
        {
          label: "Reseller controls",
          to: { path: "/reseller" }
        }
      ]
    };
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  }
};
</script>
